import React, { useState, useEffect, useCallback, useRef } from "react";
import appwriteService from "../Appwrite/Blogconfig";
import { useNavigate, useLocation } from "react-router-dom";
import { FaRegCalendarAlt, FaClock, FaSearch, FaShare, FaBookmark, FaChevronDown, FaFilter, FaEye, FaArrowRight, FaRedo } from "react-icons/fa";
import { motion, AnimatePresence, useScroll, useTransform } from "framer-motion";
import "../App.css";
import { Seo } from "../Components/index";
import blogimage from "../Assests/Image/blogimage.png";
import { Query } from "appwrite";

const Blog = () => {
  // States
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCategory, setActiveCategory] = useState("all");
  const [featuredPost, setFeaturedPost] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState("newest");
  const [savedPosts, setSavedPosts] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [fetchKey, setFetchKey] = useState(0);
  const postsPerPage = 15;
  
  // Refs to prevent unnecessary updates
  const initialRender = useRef(true);
  const lastFetchTime = useRef(0);
  
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef(null);
  const categoryNavRef = useRef(null);
  const fetchControllerRef = useRef(null);
  const { scrollY } = useScroll();
  
  // Parallax effect values
  const headerOpacity = useTransform(scrollY, [0, 300], [1, 0.3]);
  const headerScale = useTransform(scrollY, [0, 300], [1, 0.9]);
  const headerY = useTransform(scrollY, [0, 300], [0, 100]);

  // Handle sticky category nav
  useEffect(() => {
    const handleScroll = () => {
      if (categoryNavRef.current) {
        const { top } = categoryNavRef.current.getBoundingClientRect();
        setIsSticky(top <= 0);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Extract page from URL query params - FIXED to prevent refresh loops
  useEffect(() => {
    // Only parse URL params on initial render or actual URL changes
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page")) || 1;
    const category = queryParams.get("category") || "all";
    const search = queryParams.get("search") || "";
    const sort = queryParams.get("sort") || "newest";
    
    // Update states with URL values without causing a refresh loop
    setCurrentPage(page);
    setActiveCategory(category);
    setSearchQuery(search);
    setSortBy(sort);
    
    // Load saved posts from localStorage
    const saved = JSON.parse(localStorage.getItem('savedPosts') || '[]');
    setSavedPosts(saved);

    // Improved visibility change handler - only refetch after significant time away
    const handleVisibilityChange = () => {
      // Only refetch if tab becomes visible and it's been more than 60 seconds
      if (document.visibilityState === 'visible') {
        const now = Date.now();
        if (now - lastFetchTime.current > 60000) { // 60 seconds threshold
          setFetchKey(prev => prev + 1);
          lastFetchTime.current = now;
        }
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [location.search]);

  // Update URL when filters change - FIXED to prevent unnecessary URL updates
  useEffect(() => {
    // Skip initial render to prevent unnecessary URL updates on mount
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    
    // Get current URL params to see if anything changed
    const currentParams = new URLSearchParams(location.search);
    const currentPageParam = parseInt(currentParams.get("page")) || 1;
    const currentCategoryParam = currentParams.get("category") || "all";
    const currentSearchParam = currentParams.get("search") || "";
    const currentSortParam = currentParams.get("sort") || "newest";
    
    // Check if anything has actually changed to avoid unnecessary navigation
    const hasPageChanged = currentPage !== currentPageParam;
    const hasCategoryChanged = activeCategory !== currentCategoryParam;
    const hasSearchChanged = searchQuery !== currentSearchParam;
    const hasSortChanged = sortBy !== currentSortParam;
    
    if (hasPageChanged || hasCategoryChanged || hasSearchChanged || hasSortChanged) {
      // Build the new query parameters
      const queryParams = new URLSearchParams();
      
      // Only add non-default values
      if (currentPage > 1) {
        queryParams.set("page", currentPage.toString());
      }
      
      if (activeCategory !== 'all') {
        queryParams.set("category", activeCategory);
      }
      
      if (searchQuery) {
        queryParams.set("search", searchQuery);
      }
      
      if (sortBy !== 'newest') {
        queryParams.set("sort", sortBy);
      }
      
      const queryString = queryParams.toString();
      const url = queryString ? `${location.pathname}?${queryString}` : location.pathname;
      
      // Use replace to avoid adding to browser history
      navigate(url, { replace: true });
    }
  }, [currentPage, activeCategory, searchQuery, sortBy, location.pathname, navigate]);

  // Enhanced categories with icons and descriptions
  const categories = [
    { id: "all", name: "All Posts", icon: "📄", description: "Browse all blog posts" },
    { id: "web", name: "Web Development", icon: "🌐", description: "Frontend & backend web technologies" },
    { id: "mobile", name: "Mobile Dev", icon: "📱", description: "iOS, Android & cross-platform" },
    { id: "ui/ux", name: "UI/UX Design", icon: "🎨", description: "User interface & experience design" },
    { id: "database", name: "Database", icon: "🗄️", description: "SQL, NoSQL & data management" },
    { id: "cloud", name: "Cloud Computing", icon: "☁️", description: "AWS, Azure, GCP & more" },
    { id: "ai", name: "AI & ML", icon: "🤖", description: "Machine learning & artificial intelligence" }
  ];

  // Fetch posts with enhanced filtering and proper cleanup - FIXED to prevent redundant fetches
  useEffect(() => {
    const fetchPosts = async () => {
      // Cancel previous request if it exists
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
        fetchControllerRef.current = null;
      }
      
      // Set loading state
      setLoading(true);
      setError(null);
      
      // Create new AbortController for this request
      fetchControllerRef.current = new AbortController();
      
      try {
        // Record fetch time for visibility change optimization
        lastFetchTime.current = Date.now();
        
        // Build query based on filters
        const queries = [Query.equal("status", "active")];
        
        if (searchQuery) {
          queries.push(Query.search("title", searchQuery));
        }
        
        if (activeCategory !== "all") {
          queries.push(Query.equal("keywords", activeCategory));
        }
        
        // Server-side sorting - always get newest first
        queries.push(Query.orderDesc("$createdAt"));
        
        // Server-side pagination with Appwrite
        const offset = (currentPage - 1) * postsPerPage;
        const result = await appwriteService.getPosts(queries, postsPerPage, offset);
        
        // Check if request was aborted before updating state
        if (fetchControllerRef.current?.signal?.aborted) {
          return;
        }
        
        if (result) {
          // Apply client-side sorting if needed
          let sortedPosts = [...result.documents];
          
          if (sortBy === "oldest") {
            sortedPosts.sort((a, b) => new Date(a.$createdAt) - new Date(b.$createdAt));
          } else if (sortBy === "title") {
            sortedPosts.sort((a, b) => a.title.localeCompare(b.title));
          }
          
          setPosts(sortedPosts);
          setTotalPosts(result.total);
          
          // Get featured post if on first page - select the most recent post with image
          if (currentPage === 1 && sortedPosts.length > 0) {
            const postsWithImages = sortedPosts.filter(post => post.featuredImage);
            setFeaturedPost(postsWithImages.length > 0 ? postsWithImages[0] : sortedPosts[0]);
          } else if (currentPage !== 1) {
            setFeaturedPost(null); // Clear featured post when not on first page
          }
        } else {
          setError("Failed to fetch posts. Please try again.");
        }
      } catch (err) {
        // Only set error if not aborted
        if (err.name !== 'AbortError') {
          console.error("Error fetching posts:", err);
          setError("Failed to load posts. Please try again later.");
        }
      } finally {
        // Add slight delay to show loading animation
        setTimeout(() => {
          if (!fetchControllerRef.current?.signal?.aborted) {
            setLoading(false);
          }
        }, 300);
      }
    };
    
    fetchPosts();
    
    // Cleanup function to abort fetch on unmount or dependencies change
    return () => {
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort();
      }
    };
  }, [currentPage, searchQuery, activeCategory, sortBy, fetchKey]); // Added fetchKey to dependencies

  // Improved calculateReadingTime function with null checks
  const calculateReadingTime = (content) => {
    if (!content) return '1 min read';
    const wordsPerMinute = 200;
    const words = content.split(" ").length || 0;
    return `${Math.max(1, Math.ceil(words / wordsPerMinute))} min read`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const now = new Date();
    const date = new Date(dateString);
    
    // If less than 7 days ago, show relative time
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays <= 7) {
      if (diffDays === 0) {
        return "Today";
      } else if (diffDays === 1) {
        return "Yesterday";
      } else {
        return `${diffDays} days ago`;
      }
    }
    
    // Otherwise show full date
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleNextPage = useCallback(() => {
    if (currentPage * postsPerPage < totalPosts) {
      setCurrentPage((prevPage) => prevPage + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage, totalPosts, postsPerPage]);

  const handlePrevPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCategoryChange = (categoryId) => {
    setActiveCategory(categoryId);
    setCurrentPage(1);
    setShowFilters(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
  };

  const refreshPosts = () => {
    setFetchKey(prev => prev + 1);
  };

  const sharePost = (post) => {
    const title = post.title;
    const url = `${window.location.origin}/blogs/${post.$id}`;
    
    if (navigator.share) {
      navigator.share({
        title: title,
        text: `Check out this article: ${title}`,
        url: url
      }).catch(err => console.log("Error sharing:", err));
    } else {
      navigator.clipboard.writeText(url);
      
      // Show toast notification
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-6 py-3 rounded-lg shadow-lg z-50 animate-fade';
      toast.textContent = 'Link copied to clipboard!';
      document.body.appendChild(toast);
      
      setTimeout(() => {
        toast.classList.add('opacity-0');
        setTimeout(() => document.body.removeChild(toast), 300);
      }, 2000);
    }
  };

  // Generate pagination buttons with responsive design
  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    let buttons = [];
    
    // Show simplified pagination on mobile
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 640;
    
    if (isMobile) {
      // Just show current page and total
      return (
        <span className="text-sm font-medium">
          Page {currentPage} of {totalPages}
        </span>
      );
    }
    
    // Regular pagination for larger screens
    // Always show first page
    buttons.push(
      <button
        key={1}
        onClick={() => handlePageClick(1)}
        aria-label="Go to page 1"
        aria-current={currentPage === 1 ? "page" : undefined}
        className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full ${
          currentPage === 1
            ? "bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md"
            : "bg-white text-gray-700 hover:bg-gray-100 border border-gray-200"
        } flex items-center justify-center font-medium transition-all duration-300 text-sm sm:text-base`}
      >
        1
      </button>
    );

    // Show ellipsis if needed
    if (currentPage > 3) {
      buttons.push(
        <span key="ellipsis1" className="px-1 flex items-center justify-center">
          <span className="w-4 sm:w-6 text-center">...</span>
        </span>
      );
    }

    // Show current page and neighbors
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      // Don't duplicate the first or last page
      if (i === 1 || i === totalPages) continue;
      
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          aria-label={`Go to page ${i}`}
          aria-current={currentPage === i ? "page" : undefined}
          className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full ${
            currentPage === i
              ? "bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md"
              : "bg-white text-gray-700 hover:bg-gray-100 border border-gray-200"
          } flex items-center justify-center font-medium transition-all duration-300 text-sm sm:text-base`}
        >
          {i}
        </button>
      );
    }

    // Show ellipsis if needed
    if (currentPage < totalPages - 2) {
      buttons.push(
        <span key="ellipsis2" className="px-1 flex items-center justify-center">
          <span className="w-4 sm:w-6 text-center">...</span>
        </span>
      );
    }

    // Always show last page if more than one page
    if (totalPages > 1) {
      buttons.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          aria-label={`Go to page ${totalPages}`}
          aria-current={currentPage === totalPages ? "page" : undefined}
          className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full ${
            currentPage === totalPages
              ? "bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md"
              : "bg-white text-gray-700 hover:bg-gray-100 border border-gray-200"
          } flex items-center justify-center font-medium transition-all duration-300 text-sm sm:text-base`}
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  // Enhanced skeleton loader with shimmer effect
  const renderSkeletons = () => {
    // Adjust number of skeletons based on screen size
    const skeletonCount = typeof window !== 'undefined' && window.innerWidth < 768 ? 6 : postsPerPage;
    
    return Array(Math.min(skeletonCount, 9))
      .fill(0)
      .map((_, idx) => (
        <div key={idx} className="bg-white rounded-lg shadow-md overflow-hidden relative">
          <div className="w-full h-48 bg-gray-200 relative overflow-hidden">
            <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
          </div>
          <div className="p-6">
            {/* Date line */}
            <div className="flex mb-3">
              <div className="h-4 bg-gray-200 rounded w-32 relative overflow-hidden">
                <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
              </div>
            </div>
            
            {/* Title */}
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-2 relative overflow-hidden">
              <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
            </div>
            <div className="h-6 bg-gray-200 rounded w-1/2 mb-4 relative overflow-hidden">
              <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
            </div>
            
            {/* Content preview */}
            <div className="h-4 bg-gray-200 rounded w-full mb-2 relative overflow-hidden">
              <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
            </div>
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-4 relative overflow-hidden">
              <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
            </div>
            
            {/* Bottom buttons */}
            <div className="flex items-center justify-between mt-4 pt-3 border-t border-gray-100">
              <div className="h-6 w-24 bg-gray-200 rounded-full relative overflow-hidden">
                <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
              </div>
              <div className="h-8 w-8 bg-gray-200 rounded-full relative overflow-hidden">
                <div className="absolute inset-0 -translate-x-full animate-shimmer bg-gradient-to-r from-transparent via-white/20 to-transparent" />
              </div>
            </div>
          </div>
        </div>
      ));
  };

  // Add CSS animation for shimmer effect
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes shimmer {
        0% { transform: translateX(-100%); }
        100% { transform: translateX(100%); }
      }
      .animate-shimmer {
        animation: shimmer 2s infinite;
      }
      .animate-slideDown {
        animation: slideDown 0.3s forwards;
      }
      @keyframes slideDown {
        from { transform: translateY(-100%); }
        to { transform: translateY(0); }
      }
      .hide-scrollbar::-webkit-scrollbar {
        display: none;
      }
      .hide-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      @keyframes fade {
        0% { opacity: 0; }
        10% { opacity: 1; }
        90% { opacity: 1; }
        100% { opacity: 0; }
      }
      .animate-fade {
        animation: fade 2s forwards;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  Seo({
    title: "Latest Blog Posts || The Campus Coders",
    description: "Read the latest blog posts from our community of developers and tech enthusiasts.",
    imageUrl: blogimage,
    imageAlt: "Blog Posts",
    url: "https://www.thecampuscoders.com/blogs",
    keywords: "Developer Blog, Tech Blogs, Coding Tutorials, Programming Articles, Web Development"
  });

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gradient-to-b from-gray-50 to-white"
    >
      {/* Dynamic Hero Section with Parallax */}
      <motion.div 
        ref={headerRef}
        style={{ opacity: headerOpacity, scale: headerScale, y: headerY }}
        className="relative h-[300px] sm:h-[350px] md:h-[450px] bg-gradient-to-r from-blue-700 to-indigo-900 flex items-center justify-center overflow-hidden"
      >
        {/* Animated background pattern */}
        <div className="absolute inset-0">
          <svg className="absolute inset-0 h-full w-full" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M0 20h40M20 0v40" fill="none" stroke="rgba(255,255,255,0.1)" strokeWidth="1" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        </div>
        
        {/* Floating elements */}
        <motion.div 
          className="absolute w-32 h-32 bg-blue-400 rounded-full blur-3xl opacity-20"
          animate={{ 
            x: [0, 30, 0], 
            y: [0, -30, 0],
          }}
          transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
          style={{ left: '15%', top: '30%' }}
        />
        
        <motion.div 
          className="absolute w-40 h-40 bg-indigo-400 rounded-full blur-3xl opacity-20"
          animate={{ 
            x: [0, -40, 0], 
            y: [0, 20, 0],
          }}
          transition={{ repeat: Infinity, duration: 7, ease: "easeInOut" }}
          style={{ right: '20%', top: '40%' }}
        />
        
        {/* Main Content */}
        <div className="container mx-auto px-4 z-10 text-center">
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-bold text-white mb-4 sm:mb-6 drop-shadow-md"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-200">
              Code, Learn, Innovate – One Blog at a Time!
            </span>
          </motion.h1>
          
          <motion.p 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="text-base sm:text-lg md:text-xl text-blue-100 max-w-2xl mx-auto mb-6 sm:mb-8 px-2"
          >
            Discover insights, tutorials, and trending tech topics from our community of developers
          </motion.p>
          
          {/* Enhanced Search Form */}
          <motion.form
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
            onSubmit={handleSearch}
            className="relative max-w-xl mx-auto group"
          >
            <div className="relative">
              <input
                type="text"
                placeholder="What are you looking for?"
                className="w-full pl-10 sm:pl-12 pr-12 sm:pr-16 py-2 sm:py-3 md:py-4 rounded-full border-2 border-transparent focus:border-blue-300 text-gray-800 placeholder-gray-500 shadow-xl focus:outline-none transition-all duration-300 text-sm sm:text-base"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="absolute left-3 sm:left-4 top-1/2 transform -translate-y-1/2 text-gray-400">
                <FaSearch className="text-sm sm:text-base md:text-lg" />
              </div>
              <button 
                type="submit" 
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gradient-to-r from-blue-600 to-indigo-600 text-white p-1.5 sm:p-2 md:p-3 rounded-full hover:from-blue-700 hover:to-indigo-700 transition-all duration-300"
                aria-label="Search"
              >
                <FaArrowRight className="text-xs md:text-sm" />
              </button>
            </div>
            
            <motion.div 
              className="absolute -bottom-5 md:-bottom-7 left-0 right-0 text-center text-blue-200 text-xs sm:text-sm opacity-80"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 1 }}
            >
              Try searching for "web development", "react" or "javascript"
            </motion.div>
          </motion.form>
        </div>
        
        {/* Bottom fade effect */}
        <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-gray-50 to-transparent"></div>
      </motion.div>

      {/* Main Content Area */}
      <div className="container mx-auto px-4 py-6 sm:py-8 md:py-12">
        {/* Category Navigation */}
        <div ref={categoryNavRef}>
          <motion.div 
            className={`bg-white rounded-xl shadow-lg p-3 md:p-4 mb-6 sm:mb-8 md:mb-10 overflow-hidden transition-all duration-300 ${isSticky ? 'sticky top-0 z-40 shadow-xl animate-slideDown' : ''}`}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="w-full overflow-x-auto pb-2 hide-scrollbar">
              <div className="flex gap-1 sm:gap-2 md:gap-3 min-w-max">
                {categories.map(category => (
                  <button
                    key={category.id}
                    onClick={() => handleCategoryChange(category.id)}
                    className={`px-2 sm:px-3 md:px-5 py-1.5 sm:py-2 md:py-2.5 rounded-full text-xs md:text-sm font-medium transition-all duration-300 flex items-center ${
                      activeCategory === category.id 
                        ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md scale-105' 
                        : 'bg-white text-gray-700 hover:bg-gray-100 border border-gray-200 hover:shadow-sm'
                    }`}
                  >
                    <span className="mr-1 sm:mr-1.5">{category.icon}</span>
                    <span className="hidden sm:inline">{category.name}</span>
                    <span className="sm:hidden">{category.name.split(' ')[0]}</span>
                  </button>
                ))}
              </div>
            </div>
            
            {/* Active Filters - Show only when filters are applied */}
            {(activeCategory !== 'all' || searchQuery || sortBy !== 'newest') && (
              <div className="flex flex-wrap items-center gap-2 mt-3 pt-3 border-t border-gray-100">
                {activeCategory !== 'all' && (
                  <span className="bg-blue-50 text-blue-700 px-2 py-1 rounded-full text-xs md:text-sm font-medium flex items-center">
                    <span className="hidden sm:inline">Category:</span> {categories.find(c => c.id === activeCategory)?.name}
                    <button 
                      onClick={() => handleCategoryChange('all')}
                      className="ml-1 hover:text-blue-900"
                      aria-label="Clear category filter"
                    >
                      ✕
                    </button>
                  </span>
                )}
                
                {searchQuery && (
                  <span className="bg-purple-50 text-purple-700 px-2 py-1 rounded-full text-xs md:text-sm font-medium flex items-center">
                    <span className="hidden sm:inline">Search:</span> {searchQuery}
                    <button 
                      onClick={() => setSearchQuery('')}
                      className="ml-1 hover:text-purple-900"
                      aria-label="Clear search"
                    >
                      ✕
                    </button>
                  </span>
                )}
                
                {sortBy !== 'newest' && (
                  <span className="bg-gray-50 text-gray-700 px-2 py-1 rounded-full text-xs md:text-sm font-medium flex items-center">
                    <span className="hidden sm:inline">Sort:</span> {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
                    <button 
                      onClick={() => setSortBy('newest')}
                      className="ml-1 hover:text-gray-900"
                      aria-label="Clear sort"
                    >
                      ✕
                    </button>
                  </span>
                )}
                
                <button 
                  onClick={() => {
                    setActiveCategory('all');
                    setSearchQuery('');
                    setSortBy('newest');
                    setCurrentPage(1);
                  }}
                  className="text-gray-500 hover:text-gray-900 text-xs md:text-sm ml-auto underline"
                >
                  Clear all
                </button>
              </div>
            )}
          </motion.div>
        </div>

        {/* Featured Post */}
        {currentPage === 1 && featuredPost && !loading && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="mb-8 md:mb-12"
          >
            <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-6 flex items-center">
              <FaBookmark className="mr-2 md:mr-3 text-blue-600" /> 
              <span className="relative">
                Featured Post
                <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-blue-600 to-purple-600"></span>
              </span>
            </h2>
            
            <div 
              className="bg-white rounded-xl md:rounded-2xl shadow-lg md:shadow-xl overflow-hidden transform transition-all duration-500 hover:shadow-2xl group cursor-pointer"
              onClick={() => navigate(`/blogs/${featuredPost.$id}`)}
            >
              <div className="md:grid md:grid-cols-5">
                <div className="md:col-span-2 lg:col-span-3 h-56 md:h-64 lg:h-72 relative">
                  <img
                    src={appwriteService.getFilePreview(featuredPost.featuredImage)}
                    alt={featuredPost.title}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                  />
                  {/* Mobile gradient overlay */}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent md:hidden"></div>
                  {/* Mobile title overlay */}
                  <div className="absolute bottom-0 left-0 right-0 p-4 md:hidden">
                    <h3 className="text-xl font-bold text-white mb-2 drop-shadow-md">
                      {featuredPost.title}
                    </h3>
                    <div className="flex items-center text-white/90 text-sm">
                      <FaRegCalendarAlt className="mr-1" />
                      <span className="mr-3">{formatDate(featuredPost.$createdAt)}</span>
                      <FaClock className="mr-1" />
                      <span>{calculateReadingTime(featuredPost.content)}</span>
                    </div>
                  </div>
                </div>
                <div className="md:col-span-3 lg:col-span-2 p-4 md:p-6 lg:p-8 flex flex-col justify-between">
                  {/* Desktop title */}
                  <div className="hidden md:block">
                    <h3 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-4 group-hover:text-blue-600 transition-colors">
                      {featuredPost.title}
                    </h3>
                    <div className="flex items-center text-gray-500 mb-4 md:mb-6">
                      <FaRegCalendarAlt className="mr-2 text-blue-500" />
                      <span>{formatDate(featuredPost.$createdAt)}</span>
                      <span className="mx-2">•</span>
                      <FaClock className="mr-2 text-blue-500" />
                      <span>{calculateReadingTime(featuredPost.content)}</span>
                    </div>
                  </div>
                  
                  <div className="flex justify-between items-center">
                    <button 
                      className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-semibold py-3 px-6 rounded-lg transition-all transform group-hover:scale-105"
                    >
                      Read Article
                    </button>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        sharePost(featuredPost);
                      }}
                      className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                      aria-label="Share post"
                    >
                      <FaShare size={18} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {/* Blog posts grid */}
        <AnimatePresence mode="wait">
          {loading ? (
            <motion.div 
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            >
              {renderSkeletons()}
            </motion.div>
          ) : error ? (
            <motion.div 
              key="error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="bg-red-50 p-8 rounded-lg text-center"
            >
              <p className="text-red-600 mb-4 text-lg">{error}</p>
              <button 
                onClick={refreshPosts}
                className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors"
              >
                Try Again
              </button>
            </motion.div>
          ) : posts.length === 0 ? (
            <motion.div 
              key="no-posts"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center py-20"
            >
              <h3 className="text-3xl font-semibold text-gray-700 mb-4">No posts found</h3>
              <p className="text-gray-500 text-lg max-w-md mx-auto">
                {searchQuery 
                  ? `No results for "${searchQuery}". Try a different search term.` 
                  : "No blog posts are available in this category yet."}
              </p>
              <button
                onClick={() => {
                  setActiveCategory('all');
                  setSearchQuery('');
                  setSortBy('newest');
                }}
                className="mt-6 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Clear Filters
              </button>
            </motion.div>
          ) : (
            <motion.div 
              key="posts"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            >
              {posts.map((post, index) => (
                <motion.div
                  key={post.$id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="group cursor-pointer"
                  onClick={() => navigate(`/blogs/${post.$id}`)}
                >
                  <div className="bg-white rounded-xl shadow-md overflow-hidden h-full flex flex-col transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
                    <div className="h-48 overflow-hidden relative">
                      <img
                        src={appwriteService.getFilePreview(post.featuredImage)}
                        alt={post.title || "Blog post image"}
                        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                        loading="lazy"
                      />
                      {/* Category badge - if keywords exist */}
                      {post.keywords && (
                        <div className="absolute top-3 right-3">
                          <span className="bg-blue-600/80 backdrop-blur-sm text-white text-xs px-2 py-1 rounded-full">
                            {typeof post.keywords === 'string' ? post.keywords : 
                             Array.isArray(post.keywords) && post.keywords.length > 0 ? post.keywords[0] : ''}
                          </span>
                        </div>
                      )}
                    </div>
                    
                    <div className="p-5 flex flex-col flex-grow">
                      {/* Date and reading time */}
                      <div className="flex items-center text-gray-500 mb-3 text-xs">
                        <FaRegCalendarAlt className="text-blue-500 mr-1.5" />
                        <span className="mr-3">{formatDate(post.$createdAt)}</span>
                        <FaClock className="text-blue-500 mr-1.5" />
                        <span>{calculateReadingTime(post.content)}</span>
                      </div>
                      
                      <h2 className="text-xl font-bold text-gray-800 mb-3 line-clamp-2 group-hover:text-blue-600 transition-colors">
                        {post.title}
                      </h2>
                      
                      {/* Add a short preview of content */}
                      <p className="text-gray-600 text-sm line-clamp-2 mb-4 flex-grow">
                        {post.content?.replace(/<[^>]*>/g, '').substring(0, 120)}...
                      </p>
                      
                      <div className="flex justify-between items-center mt-auto pt-3 border-t border-gray-100">
                        <span className="text-blue-600 font-medium text-sm hover:text-blue-800 transition-colors flex items-center">
                          Read More
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </span>
                        
                        <button 
                          onClick={(e) => {
                            e.stopPropagation(); // This prevents navigation when clicking share
                            sharePost(post);
                          }}
                          className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                          aria-label="Share post"
                        >
                          <FaShare size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Enhanced pagination */}
        {!loading && !error && totalPosts > 0 && (
          <div className="mt-12">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <p className="text-gray-600 mb-4 sm:mb-0">
                Showing {Math.min((currentPage - 1) * postsPerPage + 1, totalPosts)} to {Math.min(currentPage * postsPerPage, totalPosts)} of {totalPosts} posts
              </p>
              
              <div className="flex items-center gap-2">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className={`rounded-full w-10 h-10 flex items-center justify-center ${
                    currentPage === 1
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-blue-100 text-blue-600 hover:bg-blue-200"
                  }`}
                  aria-label="Previous page"
                >
                  &larr;
                </button>
                
                <div className="flex items-center gap-1">
                  {renderPaginationButtons()}
                </div>
                
                <button
                  onClick={handleNextPage}
                  disabled={currentPage * postsPerPage >= totalPosts}
                  className={`rounded-full w-10 h-10 flex items-center justify-center ${
                    currentPage * postsPerPage >= totalPosts
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-blue-100 text-blue-600 hover:bg-blue-200"
                  }`}
                  aria-label="Next page"
                >
                  &rarr;
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Blog;